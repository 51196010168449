import React, { useState } from "react";

import { Dropdown } from "antd";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IdField from "./IdField";

import styles from "./search.module.scss";

const Search = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  return (
    <div className={styles.searchContainer}>
      <Dropdown
        visible={dropdownVisible}
        onVisibleChange={handleVisibleChange}
        overlay={
          <IdField closeDropdown={() => setDropdownVisible(!dropdownVisible)} />
        }
        trigger="click"
        placement="bottomRight"
      >
        <FontAwesomeIcon
          className={styles.searchIcon}
          icon={faSearch}
          size="lg"
        />
      </Dropdown>
    </div>
  );
};

export default Search;
