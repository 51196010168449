import React, { createContext, useState, useMemo } from "react";

export const ConfigurationContext = createContext();

const ConfigurationProvider = ({ children }) => {
  const [configuration, setConfiguration] = useState({});
  const [modal, setModal] = useState({
    visible: false,
    type: "",
  });

  const value = useMemo(() => {
    return {
      configuration,
      setConfiguration,
      modal,
      setModal,
    };
  }, [configuration, modal, setModal, setConfiguration]);

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
