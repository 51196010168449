import { useState, useCallback, useMemo } from "react";

import moment from "moment";

import { notification } from "antd";

import { arrayToObject } from "../../helpers";
import TransactionsService from "../../services/transactions";

import { useCancellablePromise } from "../index";

import { mapTransactions, mapTransactionsDetail, mapSourceAmount } from "./map";

function useTransactions() {
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [transactionsDetail, setTransactionsDetail] = useState([]);
  const [transactionSourceDetail, setTransactionSourceDetail] = useState({});
  const [transactionsDetailMetadata, setTransactionsDetailMetadata] = useState(
    []
  );
  const [columnDetails, setColumnDetails] = useState([]);
  const [transactionsMetadata, setTransactionsMetadata] = useState({});
  const [explainCategory, setExplainCategory] = useState([]);

  const { cancelPromises } = useCancellablePromise();

  const defaultFilters = {
    status: "status",
    timestamp: "timestamp",
    source: "source",
    amount: "amount",
    matchingsources: "matchingsources",
    insightsparameters: "insightsparameters",
    explaincategory: "explaincategory",
  };

  const getFilterOptions = useCallback(() => {
    cancelPromises();
    setLoading(true);

    return TransactionsService.getFiltersValue()
      .then((response) => {
        const filtersObjectResponse = arrayToObject(response.filters);
        const defaultFilters = [
          "amount",
          "timestamp",
          "status",
          "source",
          "matchingsources",
          "insightsparameters",
          "explaincategory",
        ];
        const parametersFilter = {};

        for (var property in filtersObjectResponse) {
          if (!defaultFilters.includes(property)) {
            parametersFilter[property] = filtersObjectResponse[property];
            delete filtersObjectResponse[property];
          }
        }
        const filtersObject = {
          ...filtersObjectResponse,
          parameters: parametersFilter,
        };
        setFilterOptions(filtersObject);
        setLoading(false);

        return filtersObject;
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExplainCategory = useCallback(() => {
    setLoading(true);
    return TransactionsService.getExpainCategory()
      .then((response) => {
        setExplainCategory(response);
        setLoading(false);
        return response;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const getTransactions = useCallback(
    (categories = [], filters, itemstart = 0) => {
      setLoading(true);
      const filtersCopy = { ...filters };
      let params = {};

      params = {
        startdate: filters?.timestamp?.startdate
          ? moment(filters.timestamp?.startdate).format("YYYY-MM-DD")
          : "",
        enddate: filters?.timestamp?.startdate
          ? moment(filters.timestamp?.enddate).format("YYYY-MM-DD")
          : "",
        status: JSON.stringify(filters?.status),
        source: JSON.stringify(filters?.source),
        amount: JSON.stringify(filters?.amount),
        matchingsources: JSON.stringify(filters?.matchingsources),
        itemstart,
        explaincategory: JSON.stringify(filters?.explaincategory),
      };

      for (const property in filtersCopy) {
        if (!(property in defaultFilters)) {
          params[property] = JSON.stringify(filtersCopy[property]);
        }
      }

      return TransactionsService.getTransactions(params).then((response) => {
        const mappedTransactions = mapTransactions(
          response.transactions,
          response.metadata,
          categories
        );
        setTransactionsMetadata(response.metadata);
        setTransactions(mappedTransactions);
        setLoading(false);
        return response;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getColumnDetails = useCallback(() => {
    setLoading(true);
    return TransactionsService.getColumnDetails()
      .then((response) => {
        setColumnDetails(response);
        setLoading(false);
        return response;
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const getTransactionDetail = useCallback((id) => {
    setLoadingDetail(true);
    TransactionsService.getTransactionDetail(id)
      .then((response) => {
        const mappedTransactionDetail = mapTransactionsDetail(
          response.transactionitems
        );
        const sourceAmount = mapSourceAmount(mappedTransactionDetail);
        setTransactionsDetailMetadata({
          id: id,
          numberofrecords: response.transactionitems.length,
          sourceAmount: sourceAmount,
        });

        setTransactionsDetail(mappedTransactionDetail);
        setLoadingDetail(false);
      })
      .catch((err) => {
        setTransactionsDetailMetadata({
          id: id,
          numberofrecords: 0,
        });
        setTransactionsDetail([]);
        setLoadingDetail(false);
      });
  }, []);

  const getTransactionSourceDetail = useCallback((id) => {
    TransactionsService.getTransactionSourceDetail(id)
      .then((response) => {
        setTransactionSourceDetail(response);
      })
      .catch((err) => setTransactionSourceDetail({}));
  }, []);

  const postExplanation = useCallback(
    (
      category,
      description,
      transactions,
      assignedTo,
      action,
      setSelectedTransactions
    ) => {
      const status = transactions[0].explanationStatus;
      const params = {
        transactionitems: transactions?.map((transaction) => transaction.key),
        matchingsources: transactions[0].matched,
        explanationtype: 0,
        explanationcategory: category,
        explanationdescription: description,
        assignedto: assignedTo,
        action,
      };
      if (status !== 1) {
        if (!category || !description) {
          notification["error"]({
            message: "Error",
            description: "Missing explanation category or description.",
          });
          return Promise.reject(new Error("Missing items"));
        } else {
          params.explanationtype = 1;
          return TransactionsService.postExplanation(params)
            .then(() => {
              notification["success"]({
                message: "Success",
                description: "Explanation added!",
              });
            })
            .catch((err) => {});
        }
      } else {
        if (category === 2) {
          params.explanationtype = 2;
        } else {
          params.explanationtype = 3;
        }
        params.explanationdescription = transactions[0].explanationDescription;
        return TransactionsService.postExplanation(params).then(() => {
          notification["success"]({
            message: "Success",
            description: "Reviewed explanation!",
          });
        });
      }
    },
    []
  );

  return useMemo(
    () => ({
      loading,
      loadingDetail,
      transactions,
      transactionsMetadata,
      transactionsDetail,
      explainCategory,
      filterOptions,
      columnDetails,
      getFilterOptions,
      getColumnDetails,
      getTransactions,
      getTransactionDetail,
      transactionsDetailMetadata,
      getTransactionSourceDetail,
      transactionSourceDetail,
      getExplainCategory,
      postExplanation,
      setLoading,
    }),
    [
      loading,
      loadingDetail,
      transactions,
      transactionsMetadata,
      transactionsDetail,
      explainCategory,
      filterOptions,
      columnDetails,
      getFilterOptions,
      getColumnDetails,
      getTransactions,
      getTransactionDetail,
      transactionsDetailMetadata,
      getTransactionSourceDetail,
      transactionSourceDetail,
      getExplainCategory,
      postExplanation,
    ]
  );
}

export default useTransactions;
