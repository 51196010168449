import { useState, useCallback, useMemo } from "react";

import OverviewService from "../../services/overview";
import { useCancellablePromise } from "../index";

function useOverview() {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [total, setTotal] = useState({
    transactions: 0,
    unmatched: 0,
    tasks: 0,
  });
  const [metadata, setMetadata] = useState({});
  const [description, setDescription] = useState("");

  const { cancelPromises } = useCancellablePromise();

  const getMetrics = useCallback((startdate, enddate) => {
    cancelPromises();
    setLoading(true);

    return OverviewService.getMetrics(startdate, enddate)
      .then((response) => {
        setMetadata(response.metadata);
        const metricsResponse = response.metrics;

        let totalResponse = 0;
        let unmatchedResponse = 0;
        let tasksResponse = 0;
        let categories = {};

        // eslint-disable-next-line array-callback-return
        metricsResponse.map((metric) => {
          totalResponse += metric.totaltransactions;
          unmatchedResponse += metric.unmatchedtransactions;
          tasksResponse += metric.tasks || 0;
          if (categories[metric.matchingsources]) {
            categories[metric.matchingsources] = [
              ...categories[metric.matchingsources],
              metric,
            ];
          } else {
            categories[metric.matchingsources] = [metric];
          }
        });
        setTotal({
          transactions: ((1 - unmatchedResponse / totalResponse) * 100).toFixed(
            2
          ),
          unmatched: unmatchedResponse,
          tasks: tasksResponse,
        });
        setMetrics(categories);
        setLoading(false);

        return response;
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDescription = useCallback(() => {
    cancelPromises();
    setLoading(true);

    return OverviewService.getDescription()
      .then((response) => {
        const descriptionResponse = response.description;

        setDescription(descriptionResponse);
        setLoading(false);

        return localStorage.getItem("descriptionClosed");
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDescription = useCallback((closed) => {
    localStorage.setItem("descriptionClosed", closed);
  }, []);

  return useMemo(
    () => ({
      loading,
      getMetrics,
      closeDescription,
      metadata,
      getDescription,
      total,
      metrics,
      description,
    }),
    [
      loading,
      getMetrics,
      getDescription,
      closeDescription,
      total,
      metrics,
      description,
      metadata,
    ]
  );
}

export default useOverview;
