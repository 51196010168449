import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";

import moment from "moment";

import { FiltersContext } from "../../../../context/Filters";
import { DateIcon } from "../../../Reusable/Icons";
import Menu from "./Menu";

import styles from "../filter.module.scss";

const Date = ({ timestamp }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Date");
  const history = useHistory();
  const { filters, setFilter, removeFilter, overviewMetadata } = useContext(
    FiltersContext
  );

  useEffect(() => {
    const startdate = filters.timestamp?.startdate;
    const enddate = filters.timestamp?.enddate;

    if (startdate && enddate) {
      const formatedStartDate = moment(startdate).format("DD-MM-YY");
      const formatedEndDate = moment(enddate).format("DD-MM-YY");
      const dateString = `${formatedStartDate} / ${formatedEndDate} `;
      setText(dateString);
    } else {
      if (history.location.pathname === "/main/overview") {
        if (overviewMetadata.metadata) {
          const formatedStartDate = moment(
            overviewMetadata?.metadata?.mintimestamp
          ).format("DD-MM-YY");
          const formatedEndDate = moment(
            overviewMetadata?.metadata?.maxtimestamp
          ).format("DD-MM-YY");
          const dateString = `${formatedStartDate} / ${formatedEndDate} `;
          setText(dateString);
        }
      } else if (history.location.pathname === "/main/transactions") {
        const formatedStartDate = moment(timestamp?.min).format("DD-MM-YY");
        const formatedEndDate = moment(timestamp?.max).format("DD-MM-YY");
        const dateString = `${formatedStartDate} / ${formatedEndDate} `;
        setText(dateString);
      } else {
        setText("Date");
      }
    }

    window.onscroll = () => {
      if (visible) {
        setVisible(false);
      }
      return () => window.removeEventListener("onscroll");
    };
  }, [
    filters,
    visible,
    overviewMetadata,
    history.location.pathname,
    timestamp,
  ]);

  return (
    <Dropdown
      overlay={
        <Menu
          closeDropdown={setVisible}
          removeFilter={removeFilter}
          setFilter={setFilter}
        />
      }
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`${styles.filterContainer} ${
          filters.timestamp?.startdate && filters.timestamp?.enddate
            ? styles.active
            : ""
        }`}
      >
        <DateIcon
          className={styles.dateIcon}
          fill={
            filters.timestamp?.startdate && filters.timestamp?.enddate
              ? "white"
              : "black"
          }
        />{" "}
        {text}
      </div>
    </Dropdown>
  );
};

export default Date;
