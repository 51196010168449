import React from "react";

import { Form, notification } from "antd";

import MenuForm from "./Form";

import styles from "./menu.module.scss";

const Menu = ({
  closeDropdown,
  setFilters,
  parameters,
  removeFilters,
  valuesArray,
  setValuesArray,
  filters,
  removeFilter,
  initialValues,
  defaultFilters,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (values.parameters.some((value) => !value.value)) {
      notification["error"]({
        message: "Invalid Parameters",
        description: "One or more parameters have not been set",
      });
    } else {
      const parametersKeys = values.parameters.map(
        (parameter) => parameter.name
      );
      const filtersCopy = [];
      if (parametersKeys.length !== 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(filters).map((filter) => {
          filtersCopy.push({ name: filter, value: filters[filter] });
        });
      } else {
        // eslint-disable-next-line array-callback-return
        Object.keys(filters).map((filter) => {
          if (defaultFilters.includes(filter)) {
            filtersCopy.push({ name: filter, value: filters[filter] });
          }
        });
      }

      setFilters([...filtersCopy, ...values.parameters]);
      setValuesArray(values.parameters);
      closeDropdown(false);
    }
  };

  const onSubmit = () => {
    form.submit();
  };

  const onRemove = () => {
    removeFilters(form.getFieldsValue().parameters);
    form.setFieldsValue({
      parameters: [],
    });

    setValuesArray([]);
    closeDropdown(false);
  };

  return (
    <div className={styles.menuContainer}>
      <div className={styles.formContainer}>
        <div className={styles.formInputContainer}>
          <MenuForm
            form={form}
            onFinish={onFinish}
            parameters={parameters}
            removeFilter={removeFilter}
            valuesArray={valuesArray}
            initialValues={initialValues}
          />
          <div className={styles.footer}>
            <button className="primary" onClick={onSubmit}>
              Apply
            </button>
            <button className="secondary" onClick={onRemove}>
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
