import React, { useState, useEffect } from "react";

import { Input, Slider, Select } from "antd";

import { CloseIcon } from "../../../../../../Reusable/Icons";

import ParameterDropdown from "./Dropdown";

import styles from "./field.module.scss";

const Field = React.forwardRef(
  ({ value, onChange, handleRemove, parameters, form, initialValue }, ref) => {
    const [selectedParameterOption, setSelectedParameterOption] = useState(
      null
    );

    useEffect(() => {
      if (initialValue) {
        setSelectedParameterOption(initialValue.name);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const findInputType = (parameter) => {
      if (parameter?.min && parameter?.max) {
        return "slider";
      } else if (parameter?.values?.length !== 0) {
        return "select";
      } else {
        return "input";
      }
    };

    const onInputChange = (changedValue) => {
      const name = selectedParameterOption;
      const inputType = findInputType(parameters[selectedParameterOption]);
      if (inputType === "input") {
        if (changedValue.target.value) {
          onChange({
            name,
            value: changedValue.target.value,
          });
        }
      } else {
        onChange({
          name,
          value: changedValue,
        });
      }
    };
    const renderInput = () => {
      const inputType = findInputType(parameters[selectedParameterOption]);

      switch (inputType) {
        case "slider":
          const min = parseFloat(parameters[selectedParameterOption].min);
          const max = parseFloat(parameters[selectedParameterOption].max);
          return (
            <Slider
              range={true}
              step={0.5}
              defaultValue={[min, max]}
              min={min}
              max={max}
              ref={ref}
              onChange={onInputChange}
            />
          );
        case "select":
          const options = parameters[selectedParameterOption]?.values?.split(
            ","
          );
          return (
            <Select
              mode="multiple"
              allowClear
              placeholder="Please select"
              maxTagCount={1}
              onChange={onInputChange}
              style={{ width: "100%", bordered: false }}
            >
              {options?.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          );
        default:
          return (
            <Input ref={ref} value={value?.value} onChange={onInputChange} />
          );
      }
    };

    return (
      <div className={styles.fieldContainer}>
        <CloseIcon onClick={handleRemove} className={styles.closeIcon} />
        <ParameterDropdown
          options={Object.keys(parameters)}
          selected={selectedParameterOption}
          setSelected={(selected) => {
            setSelectedParameterOption(selected);
            onChange({
              name: selected,
              value: null,
            });
          }}
        />

        <div style={{ width: "100%" }}>
          {selectedParameterOption && renderInput()}
        </div>
      </div>
    );
  }
);

export default Field;
