import React from "react";

import styles from "./statusblock.module.scss";

const StatusBlock = ({ status }) => {
  const renderStatus = () => {
    switch (status) {
      case 1:
        return "greenStatus";
      case 2:
        return "orangeStatus";
      case 3:
        return "greyStatus";
      default:
        return "redStatus";
    }
  };

  return <div className={styles[renderStatus()]} />;
};

export default StatusBlock;
