import React from "react";
import { NavLink } from "react-router-dom";

import { navigationItems } from "../../../../hooks/header/headerLinks";

import styles from "./index.module.scss";

const Links = ({ pathname }) => {
  return (
    <div className={styles.navigationContainer}>
      {navigationItems.map((item) => (
        <h6 key={item.link}>
          <NavLink
            exact
            to={item.link}
            activeStyle={{
              color: item.color,
            }}
            state={{ prevPath: pathname }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
            }}
          >
            {item.text}
          </NavLink>
        </h6>
      ))}
    </div>
  );
};

export default Links;
