export const statusTransactionConfig = [
  {
    value: 0,
    label: "Unmatched, no explanation",
  },
  {
    value: 1,
    label: "Explained, waiting for approval",
  },
  {
    value: 2,
    label: "Explained and approved",
  },
  {
    value: 3,
    label: "Rejected",
  },
];

export const statusAuditLogConfig = [
  {
    value: "success",
    label: "Success",
  },
  {
    value: "failure",
    label: "Failure",
  },
];
