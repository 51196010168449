import PublicService from "../base/PublicService";

class AuthService extends PublicService {
  login(email, password) {
    return this.http
      .post(`/user/login`, { email, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        return response.data;
      });
  }

  logout() {
    return this.http.post(`/user/logout`).then((response) => response.data);
  }

  sendPasswordEmail(email) {
    return this.http
      .post(`/user/forgotpassword`, { email })
      .then((response) => response.data);
  }
}

export default new AuthService();
