import React, { useState, useEffect, useContext } from "react";

import { Dropdown } from "antd";

import { FiltersContext } from "../../../../context/Filters";

import { WhiteSourceIcon, BlackSourceIcon } from "../../../Reusable/Icons";
import Menu from "./Menu";

import styles from "../filter.module.scss";

const MatchingSources = ({ matchingsources }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Matching Sources");

  const { filters, setFilter, removeFilter } = useContext(FiltersContext);

  useEffect(() => {
    const matchingSources = filters.matchingsources;
    if (!!matchingSources?.length) {
      setText(`Filtered by ${matchingSources.length}`);
    } else {
      setText("Matching Sources");
    }
  }, [filters]);
  return (
    <Dropdown
      overlay={
        <Menu
          closeDropdown={setVisible}
          setFilter={setFilter}
          removeFilter={removeFilter}
          options={matchingsources?.values?.split(",")}
          defaultValue={filters.matchingsources}
        />
      }
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`${styles.filterContainer} ${
          !!filters.matchingsources?.length ? styles.active : ""
        }`}
      >
        {!!filters.matchingsources?.length ? (
          <WhiteSourceIcon fill="white" />
        ) : (
          <BlackSourceIcon fill="black" />
        )}
        {text}
      </div>
    </Dropdown>
  );
};

export default MatchingSources;
