import React, { useContext } from "react";

import { notification } from "antd";
import { CSVLink } from "react-csv";

import { FiltersContext } from "../../../context/Filters";
import { ReportIcon } from "../../Reusable/Icons";

import styles from "./csv.module.scss";

const CSV = () => {
  const { csvData } = useContext(FiltersContext);

  const downloadPage = () => {
    notification["success"]({
      message: "Downloading",
      description: "The CSV will download soon.",
    });
  };
  return (
    <div className={styles.reportContainer} onClick={downloadPage}>
      <CSVLink data={csvData} filename="auditlog.csv">
        <ReportIcon /> Export CSV
      </CSVLink>
    </div>
  );
};

export default CSV;
