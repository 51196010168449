import React from "react";

import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import Month from "./Month";

import styles from "./header.module.scss";

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  onClick,
}) => {
  return (
    <div className={styles.headerContainer}>
      <CaretLeftOutlined
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <Month onClick={onClick} date={date} />
      <CaretRightOutlined
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </div>
  );
};

export default Header;
