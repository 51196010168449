import React, { useState } from "react";

import { Menu, Dropdown } from "antd";

import { CaretDownOutlined } from "@ant-design/icons";

import { capitalizeFirstLetter } from "../../../../../../../../helpers/index";

import styles from "./dropdown.module.scss";

const ParameterDropdown = ({ options, setSelected, selected }) => {
  const [visible, setVisible] = useState(false);

  const onSelectParameter = ({ key }) => {
    setSelected(key);
    setVisible(!visible);
  };

  const menu = (
    <Menu onClick={onSelectParameter}>
      {options.map((key) => (
        <Menu.Item key={key}>{capitalizeFirstLetter(key)}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div className={styles.dropdownContentContainer}>
        <div className={styles.parameterText}>
          {!selected ? (
            <span>Parameter</span>
          ) : (
            <span>{capitalizeFirstLetter(selected)}</span>
          )}
        </div>
        <CaretDownOutlined style={{ marginLeft: "16px" }} />
      </div>
    </Dropdown>
  );
};

export default ParameterDropdown;
