import AuthorizedService from "../base/AuthorizedService";

class TransactionsService extends AuthorizedService {
  getFiltersValue() {
    return this.http
      .get(`/transactions/getfiltervalues`)
      .then((response) => response.data);
  }

  getTransactions(params) {
    return this.http
      .get(`/transactions/overview`, { params })
      .then((response) => response.data);
  }

  getTransactionDetail(id) {
    return this.http
      .get(`/transactions/transactions/${id}`)
      .then((response) => response.data);
  }

  getTransactionSourceDetail(id) {
    return this.http
      .get(`/transactions/sourcedetails/${id}`)
      .then((response) => response.data.transactionitemdetails);
  }

  getExpainCategory() {
    return this.http
      .get(`/explaincategory`)
      .then((response) => response.data.categories);
  }

  postExplanation(params) {
    return this.http.post(`/transactions/explain`, { ...params });
  }

  getColumnDetails() {
    return this.http
      .get(`/transactions/columndetails`)
      .then((response) => response.data.columndetails);
  }
}

export default new TransactionsService();
