import React from "react";

import { EditIcon } from "../../../../../../Reusable/Icons";

import styles from "./header.module.scss";

const Header = ({ logo, name, type, onEdit }) => {
  return (
    <div className={styles.cardHeader}>
      <img className={styles.cardLogo} src={logo} alt={name} />
      {!type && <EditIcon className={styles.cardEditIcon} onClick={onEdit} />}
    </div>
  );
};

export default Header;
