import React from "react";

import { Spin } from "antd";

import FiltersProvider from "../../context/Filters";

import ComponentHeader from "../../components/Header";
import MainRoutes from "./Routes";

import styles from "./main.module.scss";

function Main({ location }) {
  return (
    <FiltersProvider>
      <div className={styles.appContainer}>
        <div className={styles.headerContainer}>
          <ComponentHeader location={location} />
        </div>
        <div
          style={{
            backgroundColor: "rgb(247, 247, 247)",
            minHeight: "calc(100vh - 128px)",
          }}
        >
          <React.Suspense
            fallback={<Spin className={styles.spinnerContainer} />}
          >
            <MainRoutes />
          </React.Suspense>
        </div>
      </div>
    </FiltersProvider>
  );
}

export default Main;
