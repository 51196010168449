import React from "react";

import { Form } from "antd";

import CardHeader from "../ListContainer/List/Card/Header";
import CardBody from "../ListContainer/List/Card/Body";
import ApiForm from "./ApiForm";
import Footer from "./Footer";

import styles from "./save.module.scss";

const Save = ({ data, addSource, uploadSource, handleClose }) => {
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.submit();
  };
  return (
    <div className={styles.saveContainer}>
      <div
        className={styles.topContainer}
        style={{
          padding: "24px",
          width: "100%",
        }}
      >
        <div
          style={{
            marginBottom: "32px",
          }}
        >
          <CardHeader
            logo={data?.logo}
            name={data?.name}
            type={data?.type || data?.sourcetype}
          />
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div className={styles.cardContainer}>
            <CardBody
              name={data.name}
              type={data?.type}
              description={data.description}
              assignedTo={data.assignedto}
              status={data.status}
              date={data?.lastupdate?.split(" ")[0]}
            />
          </div>
          <ApiForm
            data={data}
            form={form}
            addSource={addSource}
            uploadSource={uploadSource}
            handleClose={handleClose}
          />
        </div>
      </div>
      <Footer onSubmit={handleSubmit} onCancel={handleClose} />
    </div>
  );
};

export default Save;
