import React from "react";

import { notification } from "antd";

import OverviewService from "../../../services/overview";

import { ReportIcon } from "../../Reusable/Icons";

import styles from "./report.module.scss";

const Report = () => {
  const downloadPage = () => {
    OverviewService.getReport();
    notification["success"]({
      message: "Downloading",
      description: "The report will download soon.",
    });
  };
  return (
    <div className={styles.reportContainer} onClick={downloadPage}>
      <ReportIcon /> Report
    </div>
  );
};

export default Report;
