import React from "react";

import { Form } from "antd";

import Add from "./Add";

import Field from "./Field";

const MenuForm = ({ form, onFinish, parameters, initialValues }) => {
  return (
    <Form
      form={form}
      onFinish={onFinish}
      style={{
        width: "100%",
      }}
      initialValues={{ parameters: initialValues }}
    >
      <Form.List name="parameters" onChange>
        {(fields, { add, remove }, { errors }) => {
          return (
            <React.Fragment>
              {fields.map((field, index) => (
                <Form.Item
                  name="parameter"
                  {...field}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid value!",
                    },
                  ]}
                  key={field.key}
                >
                  <Field
                    field={field}
                    form={form}
                    initialValue={initialValues[index]}
                    handleRemove={() => {
                      remove(field.name);
                    }}
                    parameters={parameters}
                  />
                </Form.Item>
              ))}

              <Add handleClick={() => add()} />
            </React.Fragment>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default MenuForm;
