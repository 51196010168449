import React, { useState, useContext } from "react";

import { Modal, Input } from "antd";

import { useHistory } from "react-router-dom";

import { FiltersContext } from "../../../../../context/Filters";

import TransactionDetail from "../../../../Pages/Transactions/TransactionDetail";
import useTransactions from "../../../../../hooks/transactions";

import styles from "./idfield.module.scss";

const IdField = ({ closeDropdown }) => {
  const [term, setTerm] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const { searchTerm, setSearchTerm } = useContext(FiltersContext);

  const history = useHistory();

  const isTransactionsPage = history.location.pathname === "/main/transactions";
  const placeholderText = isTransactionsPage
    ? "Type ID here"
    : "Search for configuration";
  const text = isTransactionsPage
    ? "Search for Matched and Unmatched transactions"
    : "Search for configuration";

  const {
    transactionsDetail,
    loadingDetail,
    transactionsDetailMetadata,
    getTransactionDetail,
  } = useTransactions();

  const onChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    setSearchTerm(term);
    if (isTransactionsPage) {
      getTransactionDetail(e.target.value);
      setModalVisible(!modalVisible);
      setTerm("");
    }
    closeDropdown();
  };

  return (
    <div className={styles.idFieldContainer}>
      <Input
        value={term}
        onChange={onChange}
        className={styles.inputContainer}
        allowClear
        onPressEnter={onSubmit}
        placeholder={placeholderText}
      />
      <div className={styles.textContainer}>{text}</div>
      <Modal
        visible={modalVisible}
        title={null}
        footer={false}
        closable={false}
        destroyOnClose
        style={{ width: "80%" }}
        className={styles.transactionsDetailModal}
      >
        <TransactionDetail
          transactions={transactionsDetail}
          metadata={transactionsDetailMetadata}
          loading={loadingDetail}
          onClose={() => setModalVisible(!modalVisible)}
        />
      </Modal>
    </div>
  );
};

export default IdField;
