import axios from "axios";

import { baseURL } from "../../constants";

class PublicService {
  constructor() {
    this.http = axios.create({
      baseURL,
    });
  }
}

export default PublicService;
