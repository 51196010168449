import React, { useEffect } from "react";
import { connect } from "react-redux";
import ContainerMain from "./containers/Main";
import ContainerLogin from "./containers/Login";
import ContainerTest from "./containers/Testing";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { getUserProfile } from "./redux/actions";
import { useActions } from "./hooks/index";
import PrivateRoute from "./components/Reusable/PrivateRoute";
import "./App.less";

function App({ user }) {
  const [getUserProfileAction] = useActions([getUserProfile]);

  useEffect(() => {
    window.onstorage = (e) => {
      if (e.key === "companyid") {
        if (!e.oldValue && !!e.newValue) {
          window.location.reload();
        } else {
          getUserProfileAction(user);
        }
      } else if (e.key === "token" && !e.newValue) {
        window.location.reload();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Switch>
        {/* <Route path="/" component={ContainerTest} /> */}
        <PrivateRoute path="/main" component={ContainerMain} />

        {user && user.token ? (
          <Route render={() => <Redirect to={{ pathname: "/main" }} />} />
        ) : (
          <Route exact path="/login" component={ContainerLogin} />
        )}
        <Route render={() => <Redirect to={{ pathname: "/main" }} />} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user.data };
};

export default connect(mapStateToProps)(App);
