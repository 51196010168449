import React, { useContext } from "react";

import { FiltersContext } from "../../../context/Filters";

import Date from "./Date";
import Status from "./Status";
import Source from "./Source";
// import Amount from "./Amount";
import Category from "./Category";
import Parameters from "./Parameters";
import MatchingSources from "./MatchingSources";
import Search from "./Search";
import Rules from "./Rules";
import Action from "./Action";
import User from "./User";
import Invite from "./Invite";

import styles from "./filters.module.scss";

const Filters = ({ title, filterList, user }) => {
  const { filterOptions } = useContext(FiltersContext);
  return (
    <div className={styles.filtersContainer}>
      <span className={styles.pageTitle}>{user?.companyname}</span>
      {"TIMESTAMP" in filterList && (
        <Date timestamp={filterOptions.timestamp} />
      )}
      {"ACTION" in filterList && <Action action={filterOptions.endpoint} />}
      {"USER" in filterList && <User username={filterOptions.username} />}

      {"MATCHINGSOURCES" in filterList && (
        <MatchingSources matchingsources={filterOptions.matchingsources} />
      )}
      {"SOURCE" in filterList && <Source source={filterOptions.source} />}
      {"STATUS" in filterList && <Status />}
      {"CATEGORY" in filterList && (
        <Category category={filterOptions.explaincategory} />
      )}
      {"PARAMETER" in filterList && (
        <Parameters parameters={filterOptions.parameters} />
      )}
      <div className={styles.searchContainer}>
        {"RULES" in filterList && <Rules />}
        {"INVITE" in filterList && <Invite />}
        {"SEARCH" in filterList && <Search search={filterOptions.search} />}
      </div>
    </div>
  );
};

export default Filters;
