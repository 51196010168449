import React, { useState } from "react";

import TransactionsService from "../../../../services/transactions";

import Header from "./Header";
import TransactionsDetailTable from "./TransactionsDetailTable";

import styles from "./transactiondetail.module.scss";

const TransactionDetail = ({
  transactions,
  metadata,
  onClose,
  loading,
  columnDetails,
}) => {
  const [sourceDetailVisible, setSourceDetailVisible] = useState(false);
  const [sourceDetail, setSourceDetail] = useState({});

  const handleSourceDetail = (id) => {
    setSourceDetailVisible(!sourceDetailVisible);
    TransactionsService.getTransactionSourceDetail(id).then((response) => {
      setSourceDetail(response);
    });
  };

  return (
    <div className={styles.transactionDetailContainer}>
      <Header
        title="Transactions"
        id={metadata?.id}
        amount={metadata?.numberofrecords}
        sourceAmount={metadata?.sourceAmount}
        onClose={onClose}
      />
      <TransactionsDetailTable
        transactions={transactions}
        sourceDetail={sourceDetail}
        loading={loading}
        columnDetails={columnDetails}
        handleSourceDetail={handleSourceDetail}
      />
    </div>
  );
};

export default TransactionDetail;
