import React, { useState, useEffect, useContext, useCallback } from "react";

import { Dropdown } from "antd";

import { FiltersContext } from "../../../../context/Filters";

import {
  WhiteParametersIcon,
  BlackParametersIcon,
} from "../../../Reusable/Icons";
import Menu from "./Menu";

import styles from "../filter.module.scss";

const Parameters = ({ parameters }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Add Parameters");
  const [valuesArray, setValuesArray] = useState([]);

  const defaultFilters = [
    "amount",
    "timestamp",
    "status",
    "matchingsources",
    "source",
    "insightsparameters",
    "explaincategory",
  ];

  const {
    filters,
    setMultipleFilters,
    removeFilter,
    removeMultipleFilters,
  } = useContext(FiltersContext);

  const getCustomFilters = useCallback(
    (filters) => {
      const customFilters = [];
      for (const property in filters) {
        if (!defaultFilters.includes(property)) {
          customFilters.push({
            name: property,
            value: filters[property],
          });
        }
      }
      return customFilters;
    },
    [defaultFilters]
  );
  useEffect(() => {
    // If there are more than the default filters then at least one parameter is set
    const customFilters = getCustomFilters(filters);
    if (valuesArray.length > 0 || customFilters.length > 0) {
      setText(`Filtered by ${customFilters.length}`);
    } else {
      setText("Add Parameter");
    }
  }, [filters, getCustomFilters, valuesArray]);

  return (
    <Dropdown
      overlay={
        <Menu
          closeDropdown={setVisible}
          filters={filters}
          setFilters={setMultipleFilters}
          removeFilters={removeMultipleFilters}
          removeFilter={removeFilter}
          parameters={parameters}
          valuesArray={valuesArray}
          initialValues={getCustomFilters(filters)}
          setValuesArray={setValuesArray}
          defaultFilters={defaultFilters}
        />
      }
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`${styles.filterContainer} ${
          text !== "Add Parameter" ? styles.active : ""
        }`}
      >
        {text !== "Add Parameter" ? (
          <WhiteParametersIcon fill="white" />
        ) : (
          <BlackParametersIcon fill="black" />
        )}
        {text}
      </div>
    </Dropdown>
  );
};

export default Parameters;
