import React from "react";

import ComponentLoginParticles from "../../components/Pages/Login/Particles.js";
import ComponentLoginForm from "../../components/Pages/Login";
import styles from "./login.module.scss";

function Login(props) {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.particlesJs}>
        <ComponentLoginParticles />
        <div className={styles.loginForm}>
          <ComponentLoginForm
            location={props.location}
            history={props.history}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
