import React from "react";

import Status from "./Status";
import Content from "./Content";

import styles from "./body.module.scss";

const Body = ({ name, status, date, assignedTo, type, description }) => {
  const content = !type ? `Assigned to: ${assignedTo}` : description;
  return (
    <div className={styles.cardBody}>
      <div className={styles.cardTitle}>{name}</div>
      {!type && <Status status={status} date={date} />}
      <div style={{ marginLeft: !type && "20px" }}>
        <Content content={content} />
      </div>
    </div>
  );
};

export default Body;
