import React, { useState } from "react";

import { Space, Checkbox } from "antd";

import styles from "./menu.module.scss";

const Menu = ({
  closeDropdown,
  setFilter,
  options,
  removeFilter,
  defaultValue,
}) => {
  const [valuesArray, setValuesArray] = useState(defaultValue);
  const onSubmit = () => {
    const name = "endpoint";
    if (!valuesArray.length) {
      removeFilter(name);
    } else {
      const value = valuesArray;
      setFilter(name, value);
    }
    closeDropdown(false);
  };

  const onRemove = () => {
    closeDropdown(false);
    setValuesArray([]);
    removeFilter("endpoint");
  };

  const onChange = (values) => {
    setValuesArray(values);
  };

  return (
    <div className={styles.menuContainer}>
      <div className={styles.formContainer}>
        <Space direction="vertical" size="large">
          <Checkbox.Group
            className={styles.checkboxContainer}
            options={options}
            value={valuesArray}
            onChange={onChange}
          ></Checkbox.Group>
          <div className={styles.footer}>
            <Space direction="horizontal" size="large">
              <button
                className="primary"
                onClick={onSubmit}
                style={{ width: "90px" }}
              >
                Apply
              </button>
              <button
                className="secondary"
                style={{ width: "90px" }}
                onClick={onRemove}
              >
                Remove
              </button>
            </Space>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default Menu;
