import { useState, useCallback, useMemo } from "react";

import TransactionsService from "../../../services/transactions";

function useSourceDetail() {
  const [sourceDetailLoading, setLoading] = useState(false);
  const [detail, setDetail] = useState({});

  const getSourceDetail = useCallback((id) => {
    setLoading(true);
    return TransactionsService.getTransactionSourceDetail(id)
      .then((response) => {
        setDetail(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return useMemo(() => ({ sourceDetailLoading, detail, getSourceDetail }), [
    sourceDetailLoading,
    detail,
    getSourceDetail,
  ]);
}

export default useSourceDetail;
