import { combineReducers } from "redux";
// Imports: Reducers
import { SET_FILTER, CLEAR_FILTER } from "../types";

function data(state = {}, action) {
  switch (action.type) {
    case SET_FILTER:
			return { ...state, ...action.payload };
		case CLEAR_FILTER:
			return Object.assign({}, state, { 
				filters: {}
			})
    default:
      return state;
  }
}

const filter = combineReducers({
  data
});

export default filter;
