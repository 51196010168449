const TIMESTAMP = "TIMESTAMP";
const STATUS = "STATUS";
const SOURCE = "SOURCE";
const MATCHINGSOURCES = "MATCHINGSOURCES";
// const AMOUNT = "AMOUNT";
const PARAMETER = "PARAMETER";
const REPORT = "REPORT";
const SEARCH = "SEARCH";
const RULES = "RULES";
const AUDITLOG = "AUDITLOG";
const ACTION = "ACTION";
const USER = "USER";
const CSV = "CSV";
const INVITE = "INVITE";
const CATEGORY = "CATEGORY";

const links = {
  profile: "/main/profile",
  overview: "/main/overview",
  configuration: "/main/configuration",
  documents: "/main/documents",
  transactions: "/main/transactions",
  // testing: "/main/testing",
  insights: "/main/insights",
  rules: "/main/rules",
};

export const profileItems = [
  { text: "Profile settings", number: 1, link: links.profile },
  { text: "User management", number: 2, link: links.profile },
  // { text: "Testing Page", number: 3, link: links.testing },
];

const colors = {
  cornFlowerBlue: "#4ac2ce",
};
export const navigationItems = [
  { text: "OVERVIEW", link: links.overview, color: colors.cornFlowerBlue },
  {
    text: "TRANSACTIONS",
    link: links.transactions,
    color: colors.cornFlowerBlue,
  },
  {
    text: "INSIGHTS",
    link: links.insights,
    color: colors.cornFlowerBlue,
  },
  { text: "DOCUMENTS", link: links.documents, color: colors.cornFlowerBlue },
  {
    text: "CONFIGURATION",
    link: links.configuration,
    color: colors.cornFlowerBlue,
  },
];

export const filtersConfig = {
  profile: {},
  configuration: { INVITE, SEARCH },
  documents: {},
  overview: { TIMESTAMP, REPORT, AUDITLOG },
  transactions: {
    TIMESTAMP,
    STATUS,
    SOURCE,
    CATEGORY,
    PARAMETER,
    MATCHINGSOURCES,
    SEARCH,
    RULES,
  },
  insights: {},
  rules: { RULES },
  audit: { TIMESTAMP, ACTION, USER, STATUS, CSV },
};
