import React from "react";

import { useHistory } from "react-router-dom";

import { AuditLogIcon } from "../../Reusable/Icons";

import styles from "./auditlog.module.scss";

const Rules = () => {
  const history = useHistory();
  const isPathnameAuditLog = history.location.pathname === "/main/auditlog";
  const onClick = () => {
    if (!isPathnameAuditLog) {
      history.push("/main/auditlog");
    }
  };

  return (
    <div className={`${styles.rulesContainer}`} onClick={onClick}>
      <AuditLogIcon />
      Audit log
    </div>
  );
};

export default Rules;
