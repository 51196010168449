import React, { useState } from "react";
import { Form, Input, Upload, Button } from "antd";
import styles from "./apiForm.module.scss";

const ApiForm = ({ data, form, addSource, uploadSource, handleClose }) => {
  const [fileName, setFileName] = useState("");
  const configuration = JSON.parse(data.configuration);

  const configurationKeys = Object.keys(configuration);

  const handleBeforeUpload = (file, fileList) => {
    setFileName(file.name);
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e && e.fileList.slice(-1);
  };

  const onFinish = (values) => {
    let payload = {};
    if (data.type !== "Manual") {
      const { name } = values;
      delete values.name;
      const type = !data.connectionid ? "connectionid" : "id";
      payload = {
        configuration: values,
        name,
      };
      payload[type] = data.id;
      addSource(payload, type);
    } else {
      const file = values.file[0];
      payload = {
        sourceid: data.id,
        filename: file.name,
        file: file.originFileObj,
      };
      uploadSource(payload);
    }
    handleClose();
  };
  return (
    <div className={styles.formContainer}>
      <Form form={form} onFinish={onFinish}>
        {data.type !== "Manual" ? (
          <div>
            <label>Configuration name</label>

            <Form.Item
              name="name"
              initialValue={data.name}
              rules={[
                {
                  required: true,
                  message: "Enter a name!",
                },
              ]}
            >
              <Input placeholder={data.name}></Input>
            </Form.Item>

            <div className={styles.apiContainer}></div>
            {configurationKeys.map((key, index) => (
              <React.Fragment key={index}>
                <label>{key}</label>
                <Form.Item
                  name={key}
                  initialValue={configuration[key]}
                  rules={[
                    {
                      required: true,
                      message: `Enter ${key}!`,
                    },
                  ]}
                >
                  <Input></Input>
                </Form.Item>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div>
            <label>Choose a csv file</label>

            <div className={styles.uploadContent}>
              <div className={styles.fileName}>{fileName}</div>

              <Form.Item
                className={styles.uploadContainer}
                name="file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Select a file!",
                  },
                ]}
              >
                <Upload
                  showUploadList={false}
                  multiple={false}
                  accept=".csv"
                  beforeUpload={handleBeforeUpload}
                >
                  <Button className={styles.uploadButton}>Choose file</Button>
                </Upload>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ApiForm;
