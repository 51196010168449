import { useState, useCallback, useMemo } from "react";

import { filtersConfig } from "./headerLinks";

export function useHeader() {
  const [title, setTitle] = useState("");
  const [filterList, setFilterList] = useState({});

  const configHeader = useCallback((pathname) => {
    switch (pathname) {
      case "/main/overview":
        setTitle("OVERVIEW");
        setFilterList(filtersConfig.overview);
        break;
      case "/main/configuration":
        setTitle("CONFIGURATION");
        setFilterList(filtersConfig.configuration);
        break;
      case "/main/documents":
        setTitle("DOCUMENTS");
        setFilterList(filtersConfig.documents);
        break;
      case "/main/profile":
        setTitle("PROFILE");
        setFilterList(filtersConfig.profile);
        break;
      case "/main/transactions":
        setTitle("TRANSACTIONS");
        setFilterList(filtersConfig.transactions);
        break;
      case "/main/insights":
        setTitle("INSIGHTS");
        setFilterList(filtersConfig.insights);
        break;
      case "/main/rules":
        setTitle("RULES");
        setFilterList(filtersConfig.rules);
        break;
      case "/main/auditlog":
        setTitle("AUDIT LOG");
        setFilterList(filtersConfig.audit);
        break;
      default:
        setFilterList([]);
        break;
    }
  }, []);

  return useMemo(
    () => ({
      title,
      filterList,
      configHeader,
    }),
    [title, filterList, configHeader]
  );
}
