import React from "react";

import PropTypes from "prop-types";

import styles from "./status.module.scss";

const Status = ({ status, date }) => {
  return (
    <div className={styles.cardStatus}>
      <div
        className={styles.statusBlock}
        style={{
          backgroundColor:
            status?.toLocaleLowerCase() === "live" ? "#B6F3B8" : "#F9B4B4",
        }}
      ></div>
      <div>
        <div
          className={styles.bodyText}
        >{`Status: ${status} since ${date}`}</div>
      </div>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default Status;
