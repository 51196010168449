import React, { useState, useContext, useEffect } from "react";

import { Dropdown } from "antd";

import Menu from "./Menu";

import { FiltersContext } from "../../../../context/Filters";
import { WhiteStatusIcon, BlackStatusIcon } from "../../../Reusable/Icons";

import styles from "../filter.module.scss";

const Status = () => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Status");

  const { filters, setFilter, removeFilter } = useContext(FiltersContext);

  useEffect(() => {
    const status = filters.status;
    if (!!status?.length) {
      setText(`Filtered by ${status.length}`);
    } else {
      setText("Status");
    }
  }, [filters]);

  return (
    <Dropdown
      overlay={
        <Menu
          closeDropdown={setVisible}
          setFilter={setFilter}
          removeFilter={removeFilter}
        />
      }
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`${styles.filterContainer} ${
          !!filters.status?.length ? styles.active : ""
        }`}
      >
        {!!filters.status?.length ? (
          <WhiteStatusIcon className={styles.statusIcon} />
        ) : (
          <BlackStatusIcon className={styles.statusIcon} />
        )}
        {text}
      </div>
    </Dropdown>
  );
};

export default Status;
