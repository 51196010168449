import React, { useEffect } from "react";

import { Spin } from "antd";

import HeaderSubtitle from "../HeaderSubtitle";

import styles from "./sourceDetail.module.scss";

const DataDetail = ({ id, getDataDetail, loading, detail }) => {
  useEffect(() => {
    getDataDetail(id);
  }, [getDataDetail, id]);
  return (
    <div className={styles.sourceDetailDContainer}>
      <Spin spinning={loading}>
        <div className={styles.contentContainer}>
          <div className={styles.gridContainer}>
            {Object.keys(detail).map((key, index) => {
              return (
                <div key={key} style={{ marginBottom: "8px" }}>
                  <HeaderSubtitle
                    title={key}
                    data={detail[key]}
                    style={{ marginBottom: "8px" }}
                  />
                </div>
              );
            })}
          </div>
          {/* <div>
            <span>Configuration</span>
            {detail?.configuration &&
              Object.keys(detail?.configuration)?.map((key) => {
                return (
                  <div key={key} style={{ marginBottom: "8px" }}>
                    <HeaderSubtitle
                      title={""}
                      data={detail?.configuration[key]}
                    />
                  </div>
                );
              })}
          </div> */}
        </div>
      </Spin>
    </div>
  );
};

export default DataDetail;
