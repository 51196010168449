// Imports: Dependencies
import { combineReducers } from "redux";
// Imports: Reducers
import userReducer from "./userReducer";
import filterReducer from "./filterReducer"

// Redux: Root Reducer
const rootReducer = combineReducers({
	user: userReducer,
	filter: filterReducer
});
// Exports
export default rootReducer;
