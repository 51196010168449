import { useState, useCallback, useMemo } from "react";

import UserService from "../../services/user";

import { storeUserData, getUserProfile, logout } from "../../redux/actions";
import { useActions, useCancellablePromise } from "../index";

export function useGetUsers() {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [user, setUser] = useState({});
  const { cancelPromises } = useCancellablePromise();
  const [storeUserDataAction, getUserProfileAction] = useActions([
    storeUserData,
    getUserProfile,
    logout,
  ]);

  const getUsers = useCallback(() => {
    cancelPromises();
    setLoading(true);

    return UserService.getUsers()
      .then((response) => {
        setLoading(false);
        const { users } = response;
        storeUserDataAction({ users: users });
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  }, [cancelPromises, storeUserDataAction]);

  const getCompanies = useCallback(() => {
    setLoading(true);

    return UserService.getUserCompanies()
      .then((response) => {
        setLoading(false);
        setCompanies(response.companies);
        setUser(response.currentuser);
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  }, []);

  const switchCompany = useCallback(
    (id) => {
      setLoading(true);
      cancelPromises();
      return UserService.switchCompany(id)
        .then((response) => {
          getUserProfileAction(user);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelPromises, getUserProfileAction]
  );

  return useMemo(
    () => ({
      loading,
      companies,
      getUsers,
      getCompanies,
      setCompanies,
      switchCompany,
    }),
    [loading, companies, getUsers, getCompanies, setCompanies, switchCompany]
  );
}
