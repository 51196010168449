import React, { useState, useEffect, useContext } from "react";

import { Dropdown } from "antd";

import { FiltersContext } from "../../../../context/Filters";

import { WhiteActionIcon, BlackActionIcon } from "../../../Reusable/Icons";
import Menu from "./Menu";

import styles from "../filter.module.scss";

const Action = ({ action }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Action");

  const { filters, setFilter, removeFilter } = useContext(FiltersContext);

  useEffect(() => {
    const parameters = filters.endpoint;
    if (!!parameters?.length) {
      setText(`Filtered by ${parameters.length}`);
    } else {
      setText("Action");
    }
  }, [filters]);
  return (
    <Dropdown
      overlay={
        <Menu
          closeDropdown={setVisible}
          setFilter={setFilter}
          removeFilter={removeFilter}
          defaultValue={filters.endpoint}
          options={action?.values?.split(",")}
        />
      }
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`${styles.filterContainer} ${
          !!filters.endpoint?.length ? styles.active : ""
        }`}
      >
        {!!filters.endpoint?.length ? (
          <WhiteActionIcon fill="white" />
        ) : (
          <BlackActionIcon fill="black" />
        )}
        {text}
      </div>
    </Dropdown>
  );
};

export default Action;
