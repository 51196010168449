import AuthorizedService from "../base/AuthorizedService";


class UserService extends AuthorizedService {
	addColleague(user) {
		return this.http.post(`/user`, user).then(response => response.data)
	}
	
	getUsers() {
		return this.http.get(`/user`).then(response => response.data)
	}

	getUserInfo(email) {
		return this.http.get(`/user/${email}`).then(response => response.data)
	}

	updateUser(user) {
		return this.http.put(`/user`, user).then(response => response.data);
	}

	getUserCompanies() {
		return this.http.get("/usercompany").then(response => response.data);
	}

	switchCompany(id) {
		return this.http.post("/usercompany", { companyid: id })
	}
}

export default new UserService()
