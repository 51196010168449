import { pushPath } from "../../helpers/index";
import UserService from "../../services/user";
import { USER_LOGIN, USER_LOGOUT, SET_PROFILE, SET_FILTER } from "../types";

export function storeUserData(payload) {
  return { type: SET_PROFILE, payload };
}

export function setFilterParams(payload) {
  return { type: SET_FILTER, payload };
}

export function login(data, redirectUrl) {
  return (dispatch) => {
    dispatch(storeUserData(data));
    dispatch(getUserProfile(data, redirectUrl));
  };
}

export function logout() {
  return (dispatch) => {
    //storeUserData({ user: {} });
    dispatch({ type: USER_LOGOUT });
    localStorage.setItem("token", "");
    localStorage.setItem("companyid", "");
    pushPath("/");
  };
}

export function getUserProfile(userData, path = "/main/overview") {
  return function (dispatch) {
    return Promise.resolve(UserService.getUserInfo(userData.email))
      .then((userResponse) => {
        dispatch({ type: USER_LOGIN, payload: userResponse });
        setTimeout(() => {
          localStorage.setItem("companyid", userResponse.currentuser.company);
        }, 10);
        pushPath(
          path === "/" || path === "//" || path === "" ? "/main/overview" : path
        );
      })
      .catch((error) => {
        const response = error.response;
        dispatch({ type: USER_LOGOUT });
        pushPath("/");
        if (!response) {
          return;
        }
      });
  };
}
