import axios from "axios";
import { baseURL } from "../../constants";
import LoginService from "../login"


class AuthorizedService {
  constructor() {
    this.http = axios.create({
      baseURL,
    });

    this.http.interceptors.request.use(function (config) {
      const newConfig = { ...config };
			const token = localStorage.getItem("token");
			newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
		});
		this.http.interceptors.response.use(function (res) {
			return res;
		}, (error) => {
			if((error.response?.status === 400 && error.response?.data?.message === "Token not found") || (error.response?.status === 401)){
				LoginService.logout();
				localStorage.clear();
				window.location.reload(false);
			} 
			return Promise.reject(error)
		})
  }
}

export default AuthorizedService;
