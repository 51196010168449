import React from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";

import styles from "./content.module.scss";

const Content = ({ content }) => {
  return (
    <Tooltip>
      <div className={styles.bodyText}>{content}</div>
    </Tooltip>
  );
};

Content.propTypes = {
  content: PropTypes.string.isRequired,
};
export default Content;
