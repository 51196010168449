import React, { useState, useEffect } from "react";

import { Form, Space } from "antd";

import moment from "moment";

import { useDateFilter } from "../../../../../hooks/filters";

import Current from "./Current";
import TimePeriod from "./TimePeriod";
import Custom from "./Custom";

import styles from "./menu.module.scss";

const Menu = ({ closeDropdown, setFilter, removeFilter }) => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    startDate,
    endDate,
    calculateCurrent,
    calculatePrevious,
    calculateCustomPeriod,
  } = useDateFilter();

  const [form] = Form.useForm();
  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return <Current />;
      case 1:
        return <TimePeriod />;
      case 2:
        return <Custom />;
      default:
        return 0;
    }
  };

  const onFinish = () => {
    const name = "timestamp";
    const value = {
      startdate: moment(startDate),
      enddate: moment(endDate),
    };
    setFilter(name, value);
    closeDropdown(false);
  };

  const onFinishFailed = (values) => {};

  const onRemove = () => {
    closeDropdown(false);
    calculateCurrent();
    setActiveTab(0);
    removeFilter("timestamp");
    form.resetFields();
  };

  const onValuesChange = (fields) => {
    const { date } = fields;
    switch (activeTab) {
      case 0:
        calculateCurrent(date);
        break;
      case 1:
        calculatePrevious(date);
        break;
      case 2:
        calculateCustomPeriod(date);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    calculateCurrent();
  }, [calculateCurrent]);

  return (
    <div className={styles.menuContainer}>
      <div className={styles.tabContainer}>
        <div
          className={`${
            activeTab === 0 ? styles.activeTab : styles.inactiveTab
          }`}
          onClick={() => setActiveTab(0)}
        >
          Current
        </div>
        <div
          className={`${
            activeTab === 1 ? styles.activeTab : styles.inactiveTab
          }`}
          onClick={() => setActiveTab(1)}
        >
          Previous
        </div>
        <div
          className={`${
            activeTab === 2 ? styles.activeTab : styles.inactiveTab
          }`}
          onClick={() => setActiveTab(2)}
        >
          Custom
        </div>
      </div>
      <div className={styles.formContainer}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Form.Item name="date">{renderForm()}</Form.Item>
        </Form>
        <Space direction="vertical">
          <Space direction="vertical" size={0}>
            <span className={styles.periodLabel}>Period</span>
            <span className={styles.datePeriod}>{`${moment(startDate).format(
              "DD-MMM-YYYY"
            )} - ${moment(endDate).format("DD-MMM-YYYY")}`}</span>
          </Space>
          <Space direction="horizontal" size="large">
            <button
              className="primary"
              onClick={() => {
                form.submit();
              }}
              style={{ width: "120px" }}
            >
              Apply
            </button>
            <button
              className="secondary"
              style={{ width: "120px" }}
              onClick={onRemove}
            >
              Remove
            </button>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default Menu;
