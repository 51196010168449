import React from "react";
import Icon from "@ant-design/icons";

import { ReactComponent as AuditLogSvg } from "../../../assets/icons/audit.svg";

import { ReactComponent as DateSvg } from "../../../assets/icons/planning.svg";

// import { ReactComponent as TrophySvg } from "../../../assets/icons/trophy.svg";
// import { ReactComponent as MedalSvg } from "../../../assets/icons/medal.svg";

import { ReactComponent as BlackPersonSvg } from "../../../assets/icons/person/black_person.svg";
import { ReactComponent as WhitePersonSvg } from "../../../assets/icons/person/white_person.svg";

import { ReactComponent as WhiteActionSvg } from "../../../assets/icons/action/white_action.svg";
import { ReactComponent as BlackActionSvg } from "../../../assets/icons/action/black_action.svg";

import { ReactComponent as BlackStatusSvg } from "../../../assets/icons/status/black_status.svg";
import { ReactComponent as WhiteStatusSvg } from "../../../assets/icons/status/white_status.svg";

import { ReactComponent as WhiteSourceSvg } from "../../../assets/icons/source/white_source.svg";
import { ReactComponent as BlackSourceSvg } from "../../../assets/icons/source/black_source.svg";

import { ReactComponent as WhiteAmountSvg } from "../../../assets/icons/amount/white_amount.svg";
import { ReactComponent as BlackAmountSvg } from "../../../assets/icons/amount/black_amount.svg";

import { ReactComponent as WhiteTasksSvg } from "../../../assets/icons/tasks.svg";

import { ReactComponent as WhiteParametersSvg } from "../../../assets/icons/parameters/white_parameters.svg";

import { ReactComponent as WhiteRulesSvg } from "../../../assets/icons/rules/white_rules.svg";
import { ReactComponent as BlackRulesSvg } from "../../../assets/icons/rules/black_rules.svg";

import { ReactComponent as DescriptionSvg } from "../../../assets/icons/description.svg";
import { ReactComponent as ExplanationSvg } from "../../../assets/icons/explanation.svg";
// import { ReactComponent as EditSvg } from "../../../assets/icons/edit.svg";
import { ReactComponent as BlackParametersSvg } from "../../../assets/icons/parameters/black_parameters.svg";

import OverlapSvg from "../../../assets/icons/overlap.svg";
import ReportSvg from "../../../assets/icons/report.svg";
import CloseSvg from "../../../assets/icons/close.svg";
import EditSvg from "../../../assets/icons/edit.svg";
import MedalSvg from "../../../assets/icons/medal.svg";
import TrophySvg from "../../../assets/icons/trophy.svg";

const Overlap = () => <img src={OverlapSvg} alt="Overlap icon" />;
const Edit = () => <img src={EditSvg} alt="Edit icon" />;
const Report = () => <img src={ReportSvg} alt="Report icon" />;
const Close = () => <img src={CloseSvg} alt="Close icon" />;
const Trophy = () => <img src={TrophySvg} alt="Trophy icon" />;
const Medal = () => <img src={MedalSvg} alt="Trophy icon" />;

export const AuditLogIcon = (props) => (
  <Icon component={() => <AuditLogSvg fill={props.fill} />} {...props} />
);

export const DescriptionIcon = (props) => (
  <Icon component={() => <DescriptionSvg fill={props.fill} />} {...props} />
);

export const ExplanationIcon = (props) => (
  <Icon component={() => <ExplanationSvg fill={props.fill} />} {...props} />
);

export const DateIcon = (props) => (
  <Icon component={() => <DateSvg fill={props.fill} />} {...props} />
);

export const WhiteStatusIcon = (props) => (
  <Icon component={() => <WhiteStatusSvg fill={props.fill} />} {...props} />
);

export const BlackStatusIcon = (props) => (
  <Icon component={() => <BlackStatusSvg fill={props.fill} />} {...props} />
);

export const WhiteSourceIcon = (props) => (
  <Icon component={() => <WhiteSourceSvg fill={props.fill} />} {...props} />
);

export const BlackSourceIcon = (props) => (
  <Icon component={() => <BlackSourceSvg fill={props.fill} />} {...props} />
);

export const WhiteAmountIcon = (props) => (
  <Icon component={() => <WhiteAmountSvg fill={props.fill} />} {...props} />
);

export const BlackAmountIcon = (props) => (
  <Icon component={() => <BlackAmountSvg fill={props.fill} />} {...props} />
);

export const WhiteParametersIcon = (props) => (
  <Icon component={() => <WhiteParametersSvg fill={props.fill} />} {...props} />
);

export const BlackParametersIcon = (props) => (
  <Icon component={() => <BlackParametersSvg fill={props.fill} />} {...props} />
);

export const WhiteRulesIcon = (props) => (
  <Icon component={() => <WhiteRulesSvg fill={props.fill} />} {...props} />
);

export const BlackRulesIcon = (props) => (
  <Icon component={() => <BlackRulesSvg fill={props.fill} />} {...props} />
);

export const WhiteTasksIcon = (props) => (
  <Icon component={() => <WhiteTasksSvg fill={props.fill} />} {...props} />
);

export const WhitePersonIcon = (props) => (
  <Icon component={() => <WhitePersonSvg fill={props.fill} />} {...props} />
);

export const WhiteActionIcon = (props) => (
  <Icon component={() => <WhiteActionSvg fill={props.fill} />} {...props} />
);

export const BlackActionIcon = (props) => (
  <Icon component={() => <BlackActionSvg fill={props.fill} />} {...props} />
);

export const BlackPersonIcon = (props) => (
  <Icon component={() => <BlackPersonSvg fill={props.fill} />} {...props} />
);

export const OverlapIcon = (props) => <Icon component={Overlap} {...props} />;
export const EditIcon = (props) => <Icon component={Edit} {...props} />;
export const ReportIcon = (props) => <Icon component={Report} {...props} />;
export const CloseIcon = (props) => (
  <Icon
    style={{
      background: "#F7F7F7",
      height: "32px",
      cursor: "pointer",
    }}
    component={Close}
    {...props}
  />
);

export const TrophyIcon = (props) => (
  <Icon
    style={{
      height: "36px",
      width: "36px",
    }}
    component={Trophy}
    {...props}
  />
);
export const MedalIcon = (props) => (
  <Icon
    style={{
      height: "36px",
      width: "36px",
    }}
    component={Medal}
    {...props}
  />
);
