import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import ConfigurationProvider from "../../../context/Configuration";

import styles from "./routes.module.scss";

const OverviewPage = React.lazy(() =>
  import("../../../components/Pages/Overview")
);
const ConfigurationPage = React.lazy(() =>
  import("../../../components/Pages/Configuration")
);
const TransactionsPage = React.lazy(() =>
  import("../../../components/Pages/Transactions")
);
const InsightsPage = React.lazy(() =>
  import("../../../components/Pages/Insights")
);
const DocumentsPage = React.lazy(() =>
  import("../../../components/Pages/Documents")
);
const ProfilePage = React.lazy(() =>
  import("../../../components/Pages/Profile")
);
const RulesPage = React.lazy(() => import("../../../components/Pages/Rules"));
const AuditLogPage = React.lazy(() =>
  import("../../../components/Pages/AuditLog")
);
// const TestingContainer = React.lazy(() => import("../../Testing"));

// import TestingContainer from "../../Testing";

const Routes = ({ filters, setFilters }) => {
  return (
    <div className={styles.switchContainer}>
      <Switch>
        <Route
          exact
          path="/main/configuration"
          component={(props) => (
            <ConfigurationProvider>
              <ConfigurationPage {...props} />
            </ConfigurationProvider>
          )}
        ></Route>
        <Route path="/main/documents" component={DocumentsPage}></Route>
        <Route path="/main/profile" component={ProfilePage}></Route>
        <Route
          path="/main/overview"
          component={(props) => <OverviewPage {...props} />}
        ></Route>
        <Route
          path="/main/transactions"
          component={(props) => (
            <TransactionsPage {...props} filters={filters} />
          )}
        ></Route>
        {/* <Route
          path="/main/testing"
          component={(props) => <TestingContainer {...props} />}
        ></Route> */}
        <Route
          path="/main/insights"
          component={(props) => <InsightsPage {...props} />}
        ></Route>
        <Route exact path="/main/rules" component={RulesPage}></Route>
        <Route
          path="/main/auditlog"
          component={(props) => <AuditLogPage {...props} />}
        ></Route>
        <Route
          render={() => <Redirect to={{ pathname: "/main/overview" }} />}
        />
      </Switch>
    </div>
  );
};

export default Routes;
