import { combineReducers } from "redux";
// Imports: Reducers
import { USER_LOGIN, USER_LOGOUT, SET_PROFILE } from "../types";

function data(state = {}, action) {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return {};
    case SET_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const user = combineReducers({
  data
});

export default user;
