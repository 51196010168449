import { useEffect, useRef, useMemo } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { makeCancelable } from "../helpers";

export function useCancellablePromise(cancelable = makeCancelable) {
  const emptyPromise = Promise.resolve(true);

  // test if the input argument is a cancelable promise generator
  if (cancelable(emptyPromise).cancel === undefined) {
    throw new Error(
      "promise wrapper argument must provide a cancel() function"
    );
  }

  const promises = useRef([]);

  useEffect(() => {
    promises.current = promises.current || [];

    return cancel;
  }, []);

  function cancellablePromise(p) {
    const cPromise = cancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  function cancel() {
    promises.current.forEach((p) => p.cancel());
    promises.current = [];
  }

  return { cancellablePromise, cancelPromises: () => cancel() };
}

export function useActions(actions, deps) {
  const dispatch = useDispatch();

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch]
  );
}
