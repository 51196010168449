import React, { useEffect } from "react";

import { Radio } from "antd";

// import styles from "./tildate.module.scss";

const TilDate = React.forwardRef(({ value, onChange }, ref) => {
  useEffect(() => {
    onChange("year");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Radio.Group
      ref={ref}
      onChange={onChange}
      value={value}
      defaultValue="week"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Radio value="week">Week</Radio>
      <Radio value="month">Month</Radio>
      <Radio value="year">Year</Radio>
    </Radio.Group>
  );
});

export default TilDate;
