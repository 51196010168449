import { browserHistory } from "react-router";

export function makeCancelable(promise) {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
}

export function pushPath(path, query = "") {
  if (query && browserHistory) {
    browserHistory.push({ pathname: path, query: query });
  } else {
    if (browserHistory) {
      browserHistory.push(path);
    }
  }
}

export function arrayToObject(array) {
  return array?.reduce((obj, item) => {
    obj[item.name] = item;
    return obj;
  }, {});
}

export function localizedFormatNumber(x = 0) {
  return x.toLocaleString(undefined);
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export function removeByIndex(array, index) {
  return array?.filter((el, i) => i !== index);
}

export function findDescriptionById(categories = [], id) {
  if (categories.length) {
    const result = categories?.find((category) => category.id === id);
    return result?.description;
  } else {
    return "";
  }
}

export function findPercentage(total, count) {
  return Math.ceil(((count / total) * 100).toFixed(2));
}

export * from "./auth-header";
