import React from "react";
import { Tooltip } from "antd";
import { OverlapIcon } from "../../../../Reusable/Icons";
import StatusBlock from "./StatusBlock";
import { localizedFormatNumber } from "../../../../../helpers/index";
import styles from "./transactionsdetailtable.module.scss";

const customParamArray = [1, 2, 3, 4, 5];
const matching = [1, 2, 3].map((el) => ({
  title: `Matching ${el}`,
  dataIndex: `matching${el}`,
  key: `matching${el}`,
  width: 150,
  render: (el) => (
    <Tooltip title={el}>
      <span className={styles.customParam}>{el}</span>
    </Tooltip>
  ),
  sorter: (a, b) => a.matching2 - b.matching2,
}));

export const columns = () => [
  {
    title: "Status",
    dataIndex: "matchingStatus",
    key: "matchingStatus",
    width: 60,
    fixed: "left",
    render: (status) => <StatusBlock status={status} />,
    sorter: (a, b) => a.status - b.status,
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    width: 111,
    fixed: "left",
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.source?.localeCompare(b.source),
  },
  {
    title: "Transaction ID",
    dataIndex: "transactionid",
    key: "transactionid",
    width: 111,
    fixed: "left",
    sorter: (a, b) => a.id.localeCompare(b.id),
    render: (id) => {
      return (
        <Tooltip title={id}>
          <span className={styles.transactionIDContainer}>
            <span className={id?.length > 13 ? styles.customParam : ""}>
              {id}
            </span>
            <OverlapIcon className={styles.transactionIcon} />
          </span>
        </Tooltip>
      );
    },
  },
  {
    title: "Cardid",
    dataIndex: "cardid",
    key: "cardid",
    width: 111,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.cardid - b.cardid,
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    key: "timestamp",
    width: 160,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el} </span>
      </Tooltip>
    ),
    sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 111,
    sorter: (a, b) => a.amount - b.amount,
    render: (number) => (
      <Tooltip title={localizedFormatNumber(number)}>
        <span>{`${localizedFormatNumber(number)}`}</span>
      </Tooltip>
    ),
  },
  {
    title: "Amount Type",
    dataIndex: "amounttype",
    key: "amounttype",
    width: 111,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.amounttype?.localeCompare(b.amounttype),
  },
  {
    title: "Label",
    dataIndex: "label",
    key: "label",
    width: 100,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.label?.localeCompare(b.label),
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "supplier",
    width: 111,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.supplier?.localeCompare(b.supplier),
  },
  ...customParamArray.map((el) => ({
    title: `Custom Param ${el}`,
    dataIndex: `customparam${el}`,
    key: `customparam${el}`,
    width: 150,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.customparam1?.localeCompare(b.customparam1),
  })),
  ...matching,
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: 111,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    title: "VAT Amount",
    dataIndex: "vatamount",
    key: "vatamount",
    width: 111,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.vatamount - b.vatamount,
  },
  {
    title: "VAT Type",
    dataIndex: "vatamounttype",
    key: "vatamounttype",
    width: 111,
    render: (el) => (
      <Tooltip title={el}>
        <span className={styles.customParam}>{el}</span>
      </Tooltip>
    ),
    sorter: (a, b) => a.vatamounttype?.localeCompare(b.vatamounttype),
  },
];

export const mapColumns = (columns, columnsDetail) => {
  if (columnsDetail && columnsDetail.length > 0) {
    let mappedColumns = columns.reduce((objAcc, currEl) => {
      const currentElement = columnsDetail.find(
        (colDet) => colDet.columnOriginal === currEl.key
      );
      if (currentElement) {
        currEl.width = currentElement.columnWidth;
        currEl.title = currentElement.columnName;

        objAcc[currEl.key] = currEl;
      }
      return objAcc;
    }, {});

    mappedColumns = columnsDetail
      .map((colDet) => mappedColumns[colDet.columnOriginal])
      .filter((el) => {
        return el !== undefined;
      });

    mappedColumns[0].fixed = "left";
    mappedColumns[1].fixed = "left";
    return [columns[0], ...mappedColumns, ...matching];
  } else {
    return columns;
  }
};
