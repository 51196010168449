import React from "react";

import { CloseIcon } from "../../../../Reusable/Icons";
import HeaderSubtitle from "../../../../Reusable/HeaderSubtitle";

import styles from "./header.module.scss";

const Header = ({ title, amount, id, sourceAmount, onClose }) => {
  return (
    <div className={styles.header}>
      <h1>{title}</h1>
      <div className={styles.subtitleContainer}>
        <HeaderSubtitle title="Transaction ID" data={id} />
        <HeaderSubtitle title="Number of Transactions" data={amount} />
        {sourceAmount?.map((source) => {
          return (
            <HeaderSubtitle
              key={source.name}
              title={`${source.name} amount`}
              data={source.value}
            />
          );
        })}
      </div>

      <CloseIcon onClick={onClose} />
    </div>
  );
};

export default Header;
