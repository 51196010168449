import React from "react";
import { Button } from "antd";

import styles from "./footer.module.scss";

const Footer = ({ onSubmit, onCancel }) => {
  return (
    <div className={styles.footerContainer}>
      <button
        onClick={onCancel}
        className="secondary"
        style={{
          marginRight: "18px",
        }}
      >
        Cancel
      </button>
      <button onClick={onSubmit} className="primary">
        Save
      </button>
    </div>
  );
};

export default Footer;
