import AuthorizedService from "../base/AuthorizedService"
import FileSaver from "file-saver"

class OverviewService extends AuthorizedService {
	
	getMetrics(startdate, enddate) {
		return this.http.get(`/overview/metrics`, { params: {startdate, enddate} }).then(response => response.data)
	}
	getDescription() {
		return this.http.get(`/overview/description`).then(response => response.data)
	}

	getReport() {
		return this.http.get(`/overview/report`, {responseType: "blob"}).then(response => {
			var data = new Blob([response.data], {type: response.data.type});
			FileSaver.saveAs(data, "report")
		})
	}
}

export default new OverviewService()

