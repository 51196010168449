import React from "react";
import { connect } from "react-redux";
import styles from "./User.module.scss";

function User({ userInfo, ...props }) {
  let userStyle;
  let initials;
  let title;
  if (props.invite) {
    initials = "+";
    title = "Invite colleague";
    userStyle = styles.inviteUser;
  } else if (props.user) {
    initials =
      props.user &&
      props.user.firstname &&
      props.user.lastname &&
      props.user.firstname.charAt(0) + props.user.lastname.charAt(0);
    title =
      props.user &&
      props.user.firstname &&
      props.user.lastname &&
      `${props.user.firstname} ${props.user.prefix || ""} ${
        props.user.lastname
      }`;

    if (props.header) {
      userStyle = styles.headerUser;
    } else if (props.user) {
      userStyle = styles.colleagueUser;
    } else if (props.profile) {
      userStyle = styles.cardUser;
    }
  } else {
    initials =
      userInfo &&
      userInfo.firstname &&
      userInfo.lastname &&
      userInfo.firstname.charAt(0) + userInfo.lastname.charAt(0);
    title =
      userInfo &&
      userInfo.firstname &&
      userInfo.lastname &&
      `${userInfo.firstname} ${userInfo.prefix || ""} ${userInfo.lastname}`;

    if (props.header) {
      userStyle = styles.headerUser;
    } else if (props.user) {
      userStyle = styles.colleagueUser;
    } else if (props.profile) {
      userStyle = styles.cardUser;
    }
  }
  return (
    <div className={userStyle}>
      <div className={styles.userName}>
        <div className={styles.avatar}>{initials}</div>
        <div className={styles.userText}>
          <span className={styles.userNameTitle}>{title}</span>
          <br />

          {!props.invite && (
            <span className={styles.userCompany}>
              {userInfo?.currentuser?.companyname}
            </span>
          )}
          {!props.user && !props.invite && (
            <div>
              <span>{userInfo && userInfo.position}</span>
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { userInfo: state.user.data };
};

export default connect(mapStateToProps)(User);
