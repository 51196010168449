const findDescriptionById = (categories = [], id) => {
  if (categories.length) {
    const result = categories?.find((category) => category.id === id);
    return result?.description;
  } else {
    return "";
  }
};
export const mapTransactions = (transactions, metadata, categories) => {
  if (metadata?.numberofrecords === 0) {
    return [];
  } else {
    return transactions?.map((transaction) => {
      return {
        key: transaction.id,
        matchingsources: transaction.matchingsources,
        timestamp: transaction.timestamp,
        transactionid: transaction.transactionid,
        cardid: transaction.cardid,
        source: transaction.source,
        label: transaction.label,
        supplier: transaction.supplier,
        amount: transaction.amount,
        amounttype: transaction.amounttype,
        quantity: transaction.quantity,
        vatamount: transaction.vatamount,
        vatamounttype: transaction.vatamounttype,
        customparam1: transaction.customparam1,
        customparam2: transaction.customparam2,
        customparam3: transaction.customparam3,
        customparam4: transaction.customparam4,
        customparam5: transaction.customparam5,
        explaincategory: {
          category: findDescriptionById(
            categories,
            transaction.explaincategory
          ),
          status: transaction.status,
          explanationstatus: transaction.explanationstatus,
          categoryId: transaction.explaincategory,
        },
        explanationdescription:
          transaction.explanationdescription?.length < 1
            ? "No description"
            : transaction.explanationdescription,
        explanationstatus: transaction.explanationstatus,
        action: transaction.action,
        assignedto: transaction.assignedto,
        status: transaction.status,
      };
    });
  }
};

export const mapTransactionsDetail = (transactions) => {
  return transactions?.map((transaction) => {
    return {
      key: transaction.id,
      timestamp: transaction.timestamp,
      transactionid: transaction.transactionid,
      transactioncategory: transaction.transactioncategory,
      cardid: transaction.cardid,
      source: transaction.source,
      label: transaction.label,
      supplier: transaction.supplier,
      amount: transaction.amount,
      amountType: transaction.amounttype,
      quantity: transaction.quantity,
      vatAmount: transaction.vatamount,
      vatType: transaction.vatamounttype,
      customparam1: transaction.customparam1,
      customparam2: transaction.customparam2,
      customparam3: transaction.customparam3,
      customparam4: transaction.customparam4,
      customparam5: transaction.customparam5,
      matching1: transaction.matching1,
      matching2: transaction.matching2,
      matching3: transaction.matching3,
      explanationCategory: {
        category: transaction.explaincategory,
        status: transaction.explanationstatus,
      },
      explanationDescription:
        transaction.explanationdescription?.length < 1
          ? "No description"
          : transaction.explanationdescription,
      explainStatus: transaction.explanationstatus,
      matchingStatus: transaction.matchingstatus,
    };
  });
};

export const mapSourceAmount = (sources) => {
  const sourceKeys = [...new Set(sources?.map((source) => source.source))];
  const mappedSourceAmount = [];
  sourceKeys.forEach((key) => {
    const obj = {
      name: key,
      value: sources
        ?.filter((source) => source.source === key)
        .reduce((acc, cVal) => {
          return acc + cVal.amount;
        }, 0)
        .toFixed(2),
    };
    mappedSourceAmount.push(obj);
  });
  return mappedSourceAmount;
};
