import React from "react";

import { useHistory } from "react-router-dom";

import { BlackRulesIcon, WhiteRulesIcon } from "../../../Reusable/Icons";

import styles from "./rules.module.scss";

const Rules = () => {
  const history = useHistory();
  const isPathnameRules = history.location.pathname === "/main/rules";
  const onClick = () => {
    if (!isPathnameRules) {
      history.push("/main/rules");
    }
  };

  return (
    <div
      className={`${styles.rulesContainer} ${
        isPathnameRules ? styles.active : ""
      }`}
      onClick={onClick}
    >
      {isPathnameRules ? <WhiteRulesIcon /> : <BlackRulesIcon />}
      Rules
    </div>
  );
};

export default Rules;
