import React from "react";
import styles from "./headersubtitle.module.scss";

const HeaderSubtitles = ({ title, data }) => {
  const dataString = data?.toString();

  return (
    <div className={styles.subtitleContainer}>
      <span>{title}</span>
      {dataString?.length > 0 ? <span>{dataString}</span> : <br />}
    </div>
  );
};

export default HeaderSubtitles;
