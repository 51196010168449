import React, { useState } from "react";
import { notification, Spin, Input, Form } from "antd";

import { connect } from "react-redux";
import { login } from "../../../redux/actions";

import LoginService from "../../../services/login";

import styles from "./LoginForm.module.scss";
import actuals from "../../../assets/icons/actuals.svg";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;
    setLoading(true);
    LoginService.login(email, password)
      .then((response) => {
        const { token } = response;
        props.login({ token, email }, "/main/overview");
        setLoading(false);
      })
      .catch((e) => {
        const { message } = e?.response?.data || {
          message: "There was a problem login in",
        };
        showNotification("error", message);
        setLoading(false);
      });
  };

  const showNotification = (message, description) => {
    notification[message]({
      message: message,
      description: description,
    });
  };

  const handleLostPassword = () => {
    form
      .validateFields(["email"])
      .then((response) => {
        LoginService.sendPasswordEmail(response.email)
          .then((response) => {
            showNotification("success", response?.message);
          })
          .catch((error) => {
            showNotification("error", error?.response?.data?.message);
          });
      })
      .catch((e) => {
        // showNotification("error", "Please enter a valid E-mail address.");
      });
  };

  return (
    <React.Fragment>
      <img src={actuals} alt="actuals io logo" />
      <div className={styles.formGroup}>
        <h1>Sign In</h1>

        <Form name="login" onFinish={onFinish} form={form}>
          <label htmlFor="email">E-mail address</label>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid Email!",
              },
            ]}
          >
            <Input placeholder="E-mail address" />
          </Form.Item>

          <div className={styles.passwordLabel}>
            <label htmlFor="password">Password</label>
            <span onClick={handleLostPassword} className={styles.link}>
              Forgot password?
            </span>
          </div>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password visibilityToggle={false} placeholder="Password" />
          </Form.Item>

          <div className={styles.loginFooter}>
            <button className="primary" type="submit" disabled={loading}>
              {loading ? <Spin /> : "Sign In"}
            </button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    login: (user) => dispatch(login(user)),
  };
}

export default connect(null, mapDispatchToProps)(Login);
