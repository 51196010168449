import React from "react";

import styles from "./add.module.scss";

const Add = ({ handleClick }) => {
  return (
    <div className={styles.addContainer} onClick={handleClick}>
      <span className={styles.iconContainer}>+</span>{" "}
      <span className={styles.textContainer}>Add filter</span>
    </div>
  );
};

export default Add;
