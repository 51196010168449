import React from "react";

import { useHistory } from "react-router-dom";

import { BlackPersonIcon } from "../../../Reusable/Icons";

import styles from "./invite.module.scss";

const Rules = () => {
  const history = useHistory();
  const onClick = () => {
    history.push("/main/profile", { tab: "2" });
  };

  return (
    <div className={`${styles.inviteContainer}`} onClick={onClick}>
      <BlackPersonIcon />
      Invite Colleague
    </div>
  );
};

export default Rules;
