import React, { useState, useEffect, useContext } from "react";
import { Menu, Dropdown } from "antd";

import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { FiltersContext } from "../../../../context/Filters";

import { logout } from "../../../../redux/actions";
import { useGetUsers } from "../../../../hooks/user/user";
import ComponentProfileUser from "../../../Pages/Profile/User/User";

import { profileItems } from "../../../../hooks/header/headerLinks";
import styles from "./index.module.scss";

const ProfileDropdown = (props) => {
  const [isDropdownVisible, setDropDownVisibleState] = useState(false);

  const history = useHistory();
  const { companies, getCompanies, switchCompany } = useGetUsers();
  const { resetFilters } = useContext(FiltersContext);

  const handleLogout = (e) => {
    const { logout } = props;
    logout();
    history.push("/login");
  };

  const handleDropdownClick = (e) => {
    setDropDownVisibleState(!isDropdownVisible);
  };

  const handleSwitchCompany = (id) => {
    switchCompany(id);
    resetFilters();
    setDropDownVisibleState(!isDropdownVisible);
  };

  useEffect(() => {
    window.onscroll = () => {
      if (isDropdownVisible) {
        setDropDownVisibleState(false);
      }
      return () => window.removeEventListener("onscroll");
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const menu = (
    <Menu className={styles.menuOptions} inlineIndent={0}>
      {profileItems.map((item) => (
        <Menu.Item key={item.number} onClick={handleDropdownClick}>
          <Link
            to={{
              pathname: item.link,
              state: { tab: `${item.number}` },
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
            }}
          >
            {item.text}
          </Link>
        </Menu.Item>
      ))}

      <Menu.SubMenu
        title={<span style={{ paddingLeft: "18px" }}>Switch Company</span>}
        style={{ paddingLeft: "0px" }}
      >
        {companies.map((company) => {
          return (
            <Menu.Item
              key={company.id}
              onClick={() => handleSwitchCompany(company.id)}
              className={
                props.user?.company === company?.id
                  ? styles.selectedCompany
                  : ""
              }
            >
              {company.companyname}
            </Menu.Item>
          );
        })}
      </Menu.SubMenu>

      <Menu.Item onClick={handleDropdownClick}>
        <div onClick={handleLogout}>Log out</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.userOptions}>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        onVisibleChange={(visible) => {
          setDropDownVisibleState(visible);
        }}
        getPopupContainer={(trigger) => trigger.parentNode}
        visible={isDropdownVisible}
      >
        <div className={styles.dropdownContainer}>
          <ComponentProfileUser header />
          <div className={styles.iconContainer}>
            <FontAwesomeIcon
              icon={isDropdownVisible ? faChevronUp : faChevronDown}
              color="#979797"
            />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    logout: (user) => dispatch(logout()),
  };
}
export default connect(null, mapDispatchToProps)(ProfileDropdown);
