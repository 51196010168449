import React, { useState } from "react";

import { Space, Checkbox } from "antd";

import styles from "./menu.module.scss";

const Menu = ({
  closeDropdown,
  setFilter,
  options,
  removeFilter,
  defaultValue,
}) => {
  const [valuesArray, setValuesArray] = useState(defaultValue);

  const onSubmit = () => {
    const name = "explaincategory";
    if (!valuesArray?.length) {
      removeFilter(name);
    } else {
      const value = valuesArray;
      setFilter(name, value);
    }
    closeDropdown(false);
  };

  const onRemove = () => {
    closeDropdown(false);
    setValuesArray([]);
    removeFilter("explaincategory");
  };

  const onChange = (values) => {
    setValuesArray(values);
  };
  return (
    <div className={styles.menuContainer}>
      <div className={styles.formContainer}>
        <Space direction="vertical" size="large">
          <Checkbox.Group
            className={styles.checkboxContainer}
            options={options}
            value={valuesArray}
            onChange={onChange}
          ></Checkbox.Group>
          <Space direction="horizontal" size="large">
            <button className="primary" onClick={onSubmit}>
              Apply
            </button>
            <button className="secondary" onClick={onRemove}>
              Remove
            </button>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default Menu;
