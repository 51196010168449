import React from "react";

import styles from "./month.module.scss";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Month = ({ date, onClick }) => {
  return (
    <div className={styles.monthContainer} onClick={() => onClick(date)}>
      {months[date.getMonth()]}
    </div>
  );
};

export default Month;
