import React, { createContext, useState, useMemo, useCallback } from "react";

export const FiltersContext = createContext();

const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [overviewMetadata, setOverviewMetadata] = useState({});
  const [explainCategories, setExplainCategories] = useState([]);
  const [csvData, setCSVData] = useState([]);

  const setFilter = useCallback(
    (name, value) => {
      const filtersCopy = { ...filters };
      filtersCopy[name] = value;

      setFilters({ ...filtersCopy });
    },
    [filters]
  );

  const setMultipleFilters = useCallback(
    (multipleFilters) => {
      const filtersCopy = {};
      multipleFilters.forEach((element) => {
        filtersCopy[element.name] = element.value;
      });
      setFilters({
        ...filters,
        ...filtersCopy,
      });
    },
    [filters]
  );

  const removeMultipleFilters = useCallback(
    (multipleFilters) => {
      const filtersCopy = { ...filters };

      multipleFilters.forEach((element) => {
        delete filtersCopy[element.name];
      }, []);

      setFilters(filtersCopy);
    },
    [filters]
  );

  const removeFilter = useCallback(
    (name) => {
      var filtersCopy = { ...filters };
      delete filtersCopy[name];
      setFilters(filtersCopy);
    },
    [filters]
  );

  const setMetadata = useCallback((type, name, value) => {
    if (type === "overview") {
      const obj = {};
      obj[name] = value;

      setOverviewMetadata(obj);
    } else {
    }
  }, []);

  const setOptions = useCallback((options) => {
    setFilterOptions({ ...options });
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  const value = useMemo(() => {
    return {
      filters,
      searchTerm,
      overviewMetadata,
      filterOptions,
      csvData,
      explainCategories,
      setExplainCategories,
      setCSVData,
      setFilter,
      setMultipleFilters,
      setOptions,
      removeFilter,
      removeMultipleFilters,
      setMetadata,
      setSearchTerm,
      resetFilters,
    };
  }, [
    filters,
    searchTerm,
    overviewMetadata,
    filterOptions,
    csvData,
    explainCategories,
    setExplainCategories,
    setFilter,
    setMultipleFilters,
    setOptions,
    removeFilter,
    removeMultipleFilters,
    setMetadata,
    resetFilters,
  ]);

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

export default FiltersProvider;
