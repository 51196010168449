import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const isClient = true,
  isDebug = true;
const persistConfig = {
  // Root
  key: "root",
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ["user"],
  // Blacklist (Don't Save Specific Reducers)
  //blacklist: ["nameReducer"]
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);
let store;

if (isClient && isDebug) {
  store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk),
      typeof window === "object" &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );
} else {
  store = createStore(persistedReducer, applyMiddleware(thunk));
}

// Middleware: Redux Persist Persister
let persistor = persistStore(store);
// Exports
export { store, persistor };
