import React, { useEffect, useState } from "react";
import moment from "moment";

import DatePicker from "react-datepicker";

import Header from "./Header";
import "./datepicker.scss";

const Custom = ({ value, onChange }) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "weeks").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChange({
      startDate: moment(start).format("MM-DD-YYYY"),
      endDate: moment(end).format("MM-DD-YYYY"),
    });
  };

  const selectAllMonth = (date) => {
    const endDateInfo = moment(date).endOf("month").toDate();
    const startDateInfo = moment(date).startOf("month").toDate();
    onRangeChange([startDateInfo, endDateInfo]);
  };

  useEffect(() => {
    onChange({
      startDate: moment(startDate).format("MM-DD-YYYY"),
      endDate: moment(endDate).format("MM-DD-YYYY"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDayClassname = (date) => {
    if (
      moment(date).isAfter(startDate) &&
      moment(date).isBefore(moment(endDate).startOf("day"))
    ) {
      return "range-between-highlight";
    } else {
      return;
    }
  };
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Header
          date={date}
          onClick={selectAllMonth}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
      startDate={startDate}
      endDate={endDate}
      onChange={onRangeChange}
      dayClassName={renderDayClassname}
      selectsRange
      inline
    />
  );
};

export default Custom;
