import React, { useState, useEffect, useContext } from "react";

import { Dropdown } from "antd";

import { FiltersContext } from "../../../../context/Filters";

import { WhiteAmountIcon, BlackAmountIcon } from "../../../Reusable/Icons";
import Menu from "./Menu";

import styles from "../filter.module.scss";

const Category = ({ category }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("Category");

  const { filters, setFilter, removeFilter, explainCategories } = useContext(
    FiltersContext
  );

  useEffect(() => {
    const explaincategory = filters.explaincategory;
    if (!!explaincategory?.length) {
      setText(`Filtered by ${explaincategory.length}`);
    } else {
      setText("Category");
    }
  }, [filters]);
  return (
    <Dropdown
      overlay={
        <Menu
          closeDropdown={setVisible}
          setFilter={setFilter}
          removeFilter={removeFilter}
          options={explainCategories}
          defaultValue={filters.explaincategory}
        />
      }
      getPopupContainer={(trigger) => {
        return trigger.parentNode;
      }}
      trigger="click"
      visible={visible}
      placement="bottomCenter"
      overlayStyle={{ height: "100%" }}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`${styles.filterContainer} ${
          !!filters.explaincategory?.length ? styles.active : ""
        }`}
      >
        {!!filters.explaincategory?.length ? (
          <WhiteAmountIcon fill="white" />
        ) : (
          <BlackAmountIcon fill="black" />
        )}
        {text}
      </div>
    </Dropdown>
  );
};

export default Category;
