import React, { useState } from "react";
import { Table } from "antd";

import useSourceDetail from "../../../../../hooks/transactions/sourcedetail";
import DataDetail from "../../../../Reusable/DataDetail";
import { mapColumns, columns } from "./columns";

import styles from "./transactionsdetailtable.module.scss";

const TransactionsDetailTable = ({ transactions, loading, columnDetails }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const { detail, sourceDetailLoading, getSourceDetail } = useSourceDetail();

  const handleRowExpand = (record) => {
    const expandedRowsCopy = expandedRowKeys.includes(record.key)
      ? expandedRowKeys.filter((key) => key !== record.key)
      : [...expandedRowKeys, record.key];
    setExpandedRowKeys(expandedRowsCopy);
  };

  return (
    <Table
      className={styles.tableContainer}
      loading={loading}
      dataSource={transactions}
      rowKey="key"
      bordered
      pagination={false}
      columns={mapColumns(columns(), columnDetails)}
      scroll={{ x: 700, y: 466 }}
      expandedRowKeys={expandedRowKeys}
      onExpand={(expanded, record) => handleRowExpand(record)}
      expandedRowRender={(record) => (
        <DataDetail
          id={record.key}
          loading={sourceDetailLoading}
          detail={detail}
          getDataDetail={getSourceDetail}
        />
      )}
      showSorterTooltip={false}
    ></Table>
  );
};

export default TransactionsDetailTable;
