import { useState, useCallback, useMemo } from "react";

import moment from "moment";

import useOverview from "../overview";

export function useDateFilter() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { getMetrics } = useOverview();

  const calculateCurrent = useCallback((period = "year") => {
    let datePeriod = period === "week" ? "isoWeek" : period;

    const endDateInfo = moment().endOf(datePeriod).format("MM-DD-YYYY");
    const startDateInfo = moment().startOf(datePeriod).format("MM-DD-YYYY");

    setStartDate(startDateInfo);
    setEndDate(endDateInfo);

    return [startDateInfo, endDateInfo];
  }, []);

  const calculatePrevious = useCallback((period) => {
    let datePeriod = period === "week" ? "isoWeek" : period;

    const endDateInfo = moment()
      .subtract(1, datePeriod)
      .endOf(datePeriod)
      .format("MM-DD-YYYY");
    const startDateInfo = moment()
      .subtract(1, datePeriod)
      .startOf(datePeriod)
      .format("MM-DD-YYYY");

    setStartDate(startDateInfo);
    setEndDate(endDateInfo);
    return [startDateInfo, endDateInfo];
  }, []);

  const calculateCustomPeriod = useCallback((date) => {
    const endDateInfo = moment(date.endDate).format("MM-DD-YYYY");
    const startDateInfo = moment(date.startDate).format("MM-DD-YYYY");
    setStartDate(startDateInfo);
    setEndDate(endDateInfo);

    return [startDateInfo, endDateInfo];
  }, []);

  const applyDateFilter = useCallback(() => {
    const formatedStartDate = moment(startDate).format("YYYY-DD-MM");
    const formatedEndDate = moment(endDate).format("YYYY-DD-MM");
    getMetrics(formatedStartDate, formatedEndDate);
  }, [startDate, endDate, getMetrics]);

  return useMemo(
    () => ({
      startDate,
      endDate,
      calculateCurrent,
      calculatePrevious,
      applyDateFilter,
      calculateCustomPeriod,
    }),
    [
      startDate,
      endDate,
      calculateCurrent,
      calculatePrevious,
      calculateCustomPeriod,
      applyDateFilter,
    ]
  );
}
