import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { useHeader } from "../../hooks/header";
// import useTasks from "../../hooks/tasks";
import actuals from "../../assets/icons/actuals.svg";

import ProfileDropdown from "./Navigation/Dropdown";
import NavigationLinks from "./Navigation/Links";

import Filters from "./Filters";
import Report from "./Report";
// import Tasks from "./Tasks";
import AuditLog from "./AuditLog";
import CSV from "./CSV";

import styles from "./header.module.scss";

function Header({ location, user }) {
  // const [tasksDrawerIsVisible, setTasksDrawerIsVisible] = useState(false);
  const { pathname } = location;
  const { title, filterList, configHeader } = useHeader();
  // const { loading, totalTasks, tasksList, getTasks } = useTasks();

  useEffect(() => {
    // Find out the path to show/hide specific text and filters
    configHeader(pathname);
    // getTasks();
  }, [configHeader, pathname]);

  // const handleTasks = () => {
  //   setTasksDrawerIsVisible(!tasksDrawerIsVisible);
  // };

  return (
    <div className={styles.header}>
      {/* Refactor these to their own "top header" component*/}
      <div key="topHeaderContainer" className={styles.optionsContainer}>
        <Link to="/main/overview">
          <img
            className={styles.actualsLogo}
            src={actuals}
            alt="actuals io logo"
          />
        </Link>
        <NavigationLinks pathname={pathname} />
        <ProfileDropdown user={user} />
      </div>
      <div key="bottomHeaderContainer" className={styles.optionsContainer}>
        <Filters filterList={filterList} title={title} user={user} />
        {/* Refactor these to the filters component */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {"REPORT" in filterList && (
            <div className={styles.reportContainer}>
              <Report />
            </div>
          )}
          {"AUDITLOG" in filterList && (
            <div className={styles.reportContainer}>
              <AuditLog />
            </div>
          )}
          {"CSV" in filterList && <CSV />}

          {/* <Tasks
            loading={loading}
            totalTasks={totalTasks}
            tasksList={tasksList}
            visible={tasksDrawerIsVisible}
            onTasksClick={handleTasks}
            onClose={() => setTasksDrawerIsVisible(!tasksDrawerIsVisible)}
          /> */}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.data.currentuser,
  };
}

export default connect(mapStateToProps)(Header);
